import React from 'react';
import Image from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styles from './styles.module.scss';

const ProductGrid = () => {
  //const { store: {checkout} } = useContext(StoreContext)
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(
          sort: {
            fields: [publishedAt]
            order: ASC
          }
        ) {
          edges {
            node {
              id
              title
              handle
              createdAt
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  )

  /*
  const getPrice = price => Intl.NumberFormat(undefined, {
    currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(parseFloat(price ? price : 0))
  */
  const getPrice = price => parseFloat(price).toFixed(2);

  return (
    <div className={styles.grid}>
      {allShopifyProduct.edges
        ? allShopifyProduct.edges.map(({ node: { id, handle, title, images: [firstImage], variants: [firstVariant] } }) => (
          <div className={styles.product} key={id} >
            <Link to={`/product/${handle}/`}>
              {firstImage && firstImage.localFile &&
                (<Image
                  fluid={firstImage.localFile.childImageSharp.fluid}
                  alt={handle}
                  loading="lazy"
                />)}
            </Link>
            <span className={styles.title}>{title}</span>
            <span className={styles.price}>from ${getPrice(firstVariant.price)}</span>
          </div>
        ))
        : <p>No Products found!</p>}
    </div>
  )
}

export default ProductGrid
