import React from 'react';
import SEO from '~/components/seo';
import ProductGrid from '~/components/ProductGrid';
import { PageType } from '~/components/Trekkie';

const IndexPage = () => (
  <>
    <PageType type="home" />
    <SEO title="Pop Local - Regional Style Popcorn" fullTitle keywords={[`popcorn`, `new orleans`, `cajun`]} />
    <ProductGrid />
  </>
)

export default IndexPage
